import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import { FaAngleLeft } from "react-icons/fa"
import SEO from "../components/seo"
import "./404.sass"

const NotFoundPage = () => (
  <>
    <SEO title="404: Page not found" />
    <div className="spacer-3" />
    <div className="container">
      <p>
        <AniLink
          swipe
          duration={0.3}
          entryOffset={100}
          direction="right"
          to="/"
        >
          <FaAngleLeft /> Home
        </AniLink>
      </p>
      <h1>404: This page is on an indefinite spring break.</h1>
    </div>
    <StaticQuery
      query={graphql`
        query {
          bannerImg: file(relativePath: { eq: "dsc00056.jpg" }) {
            sm: childImageSharp {
              fluid(quality: 100, maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
            md: childImageSharp {
              fluid(quality: 100, maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
            lg: childImageSharp {
              fluid(quality: 100, maxWidth: 992) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
            xl: childImageSharp {
              fluid(quality: 100, maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={data => (
        <BackgroundImage
          fluid={[
            data.bannerImg.sm.fluid,
            {
              ...data.bannerImg.md.fluid,
              media: `(min-width: 768px)`,
            },
            {
              ...data.bannerImg.lg.fluid,
              media: `(min-width: 992px)`,
            },
            {
              ...data.bannerImg.xl.fluid,
              media: `(min-width: 1200px)`,
            },
          ]}
          backgroundColor={`#818365`}
          className="banner-404"
          style={{ backgroundPosition: "center 22.5%" }}
        />
      )}
    />
    <div className="spacer-2" />
  </>
)

export default NotFoundPage
